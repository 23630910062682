// @flow

import * as React from 'react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';

import createApolloClient from './createApolloClient';

type Props = {
  // eslint-disable-next-line react/require-default-props
  isServer?: boolean,
  // eslint-disable-next-line react/require-default-props
  apolloCache?: InMemoryCache,
  children: React.Node,
};

export default function AppProvider(props: Props) {
  const { isServer = false, apolloCache, children } = props;

  const apolloClient = React.useMemo(
    () => createApolloClient({ ssrMode: isServer, cache: apolloCache || new InMemoryCache() }),
    [isServer, apolloCache],
  );

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
