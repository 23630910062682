/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// @flow

import * as React from 'react';

type Props = {
  onOverlayClick: () => void,
  drawerShown: boolean,
  children: React.Node,
};

export default function DrawerSafeAreaView(props: Props) {
  const { onOverlayClick, drawerShown, children } = props;

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        // justifyContent: 'center',
      }}
    >
      <div
        onClick={onOverlayClick}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.500)',
          opacity: drawerShown ? 1 : 0,
          transition: 'opacity 0.2s',
          pointerEvents: drawerShown ? 'initial' : 'none',
        }}
      />
      {children}
    </div>
  );
}
