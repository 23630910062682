// @flow

import * as React from 'react';

import { type DataSource } from './dataSource';
import {
  type DataState,
  dataStateFromMaybeDataPromise,
  dataStateFromData,
  dataStateFromError,
} from './dataState';
import { type DataStateCache } from './dataStateCache';

export function readDataState<Value>(dataState: DataState<Value>): Value {
  // console.log('readDataState', dataState);
  if (dataState.loading) throw dataState.loading;
  if (dataState.error) throw dataState.error;
  return dataState.data;
}

export function useReadDataSource<Value, Identifier>(
  cache: DataStateCache,
  dataSource: DataSource<Value, Identifier>,
  identifier: Identifier,
): () => DataState<Value> {
  // console.log('useDataSource', dataSource, identifier);
  const initialDataState = React.useMemo(
    () => {
      if (cache.has(dataSource, identifier)) {
        // console.log('cache hit');
        return cache.get(dataSource, identifier);
      }
      // console.log('cache miss');
      const dataState = dataStateFromMaybeDataPromise(dataSource.get(identifier));
      cache.set(dataSource, identifier, dataState);
      return dataState;
    },
    [dataSource, identifier],
  );

  // console.log('useDataSource initialDataState', initialDataState);

  const [dataState, setDataState] = React.useState(initialDataState);

  const updateDataState = (nextDataState) => {
    cache.set(dataSource, identifier, nextDataState);
    // console.log('nextDataState', nextDataState);
    setDataState(nextDataState);
  };

  React.useMemo(
    () => {
      if (dataState.loading) {
        dataState.loading.then(updateDataState);
      }
    },
    [dataState.loading],
  );

  React.useEffect(
    () => {
      if (dataSource.getChanges) {
        const subscription = dataSource
          .getChanges(identifier)
          .map(dataStateFromData)
          .subscribe({
            next: updateDataState,
            error: (error) => updateDataState(dataStateFromError(error)),
          });
        return () => subscription.unsubscribe();
      }
      return null;
    },
    [dataSource, identifier],
  );

  // console.log('useDataSource dataState', dataState);

  return () => {
    const data = readDataState(dataState);
    return data;
  };
}

export function useDataSource<Value, Identifier>(
  cache: DataStateCache,
  dataSource: DataSource<Value, Identifier>,
  identifier: Identifier,
): DataState<Value> {
  const readDataSource = useReadDataSource(cache, dataSource, identifier);
  const dataState = readDataSource();
  return dataState;
}

export function useReadData<Value, Identifier>(
  cache: DataStateCache,
  dataSource: DataSource<Value, Identifier>,
  identifier: Identifier,
): () => Value {
  const dataState = useDataSource(cache, dataSource, identifier);
  const readData = React.useMemo(() => () => dataState, [dataState]);
  return readData;
}

export function useData<Value, Identifier>(
  cache: DataStateCache,
  dataSource: DataSource<Value, Identifier>,
  identifier: Identifier,
): Value {
  const readData = useReadData(cache, dataSource, identifier);
  const data = readData();
  return data;
}
