// @flow

import isThenable from './utilities/isThenable';

export type LoadedWithSuccessDataState<Value> = {
  loading: false,
  data: Value,
  error: Error | null,
};

export type LoadedWithErrorDataState<Value> = {
  loading: false,
  data: Value | null,
  error: Error,
};

export type LoadedDataState<Value> =
  | LoadedWithSuccessDataState<Value>
  | LoadedWithErrorDataState<Value>;

export type LoadingDataState<Value> = {
  loading: Promise<LoadedDataState<Value>>,
  data: Value | null,
  error: Error | null,
};

export type SyncDataState<Value> = LoadedDataState<Value>;

export type AsyncDataState<Value> = LoadedDataState<Value> | LoadingDataState<Value>;

export type DataState<Value> = SyncDataState<Value> | AsyncDataState<Value>;

export function dataStateFromError<Value>(
  error: Error,
  prevState?: DataState<Value>,
): LoadedWithErrorDataState<Value> {
  return {
    loading: false,
    // TODO: Think if this is correct
    data: prevState && !prevState.loading ? prevState.data : null,
    error,
  };
}

export function dataStateFromData<Value>(data: Value): LoadedWithSuccessDataState<Value> {
  return {
    loading: false,
    data,
    error: null,
  };
}

export function dataStateFromDataPromise<Value>(
  dataPromise: Promise<Value>,
  prevState?: DataState<Value>,
): LoadingDataState<Value> {
  const dataStatePromise = dataPromise.then(dataStateFromData, dataStateFromError);
  return {
    loading: dataStatePromise,
    data: prevState ? prevState.data : null,
    error: null,
  };
}

export function dataStateFromMaybeDataPromise<Value>(
  maybeDataPromise: Promise<Value> | Value,
  prevState?: DataState<Value>,
): AsyncDataState<Value> {
  if (isThenable(maybeDataPromise)) {
    const dataPromise: Promise<Value> =
      // $FlowFixMe
      maybeDataPromise;
    return dataStateFromDataPromise(dataPromise, prevState);
  }

  const data: Value =
    // $FlowFixMe
    maybeDataPromise;

  return dataStateFromData(data);
}
