// @flow

import * as React from 'react';
import { Link } from 'react-router-dom';

export default function NavigationDrawer() {
  return (
    <>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Link to="/">Home Page</Link>
        <Link to="/cards">Cards Pagge</Link>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <div>GradRx © CURRENT YEAR</div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            padding: '12px 20px',
          }}
        >
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/tos">Terms of Service</Link>
        </div>
      </div>
    </>
  );
}
