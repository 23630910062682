// @flow

import * as React from 'react';

import { globalContext, type Global } from './context';

type UseGlobal = <MappedGlobal>(map: (global: Global) => MappedGlobal) => MappedGlobal;
export const useGlobal: UseGlobal = (map) => {
  const global = React.useContext(globalContext);
  const mappedGlobal = React.useMemo(() => map(global), [map, global]);
  return mappedGlobal;
};

const mapGlobalToFirebase = (global) => global.firebase;
export const useFirebase = () => useGlobal(mapGlobalToFirebase);

const mapGlobalToDataStateCache = (global) => global.dataStateCache;
export const useDataStateCache = () => useGlobal(mapGlobalToDataStateCache);

const mapGlobalToDataSources = (global) => global.dataSources;
export const useDataSources = () => useGlobal(mapGlobalToDataSources);
