// @flow

import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { InMemoryCache } from 'apollo-cache-inmemory';

import firebase from './firebase';

import { GlobalProvider, AppProvider } from '../App';

// const apolloCache = new InMemoryCache().restore(window.__APOLLO_STATE__);

type Props = {
  apolloCache: InMemoryCache,
  // eslint-disable-next-line react/require-default-props
  ssr?: boolean,
  children: React.Node,
};

function Provider(props: Props, ref) {
  const { apolloCache, ssr = false, children } = props;

  // $FlowFixMe
  const [hydrateComplete, setHydrateComplete] = React.useState(false);
  // $FlowFixMe
  React.useImperativeMethods(ref, () => ({
    onHydrateComplete: () => {
      if (hydrateComplete) throw new Error('onHydrateComplete() was called more than once');

      setHydrateComplete(true);

      console.info('Hydration complete');
    },
  }));

  return (
    <HelmetProvider>
      <BrowserRouter>
        <GlobalProvider firebase={!ssr || hydrateComplete ? firebase : null}>
          <AppProvider apolloCache={apolloCache}>
            {/* $FlowFixMe */}
            <React.Suspense fallback="Terrible loading message.">{children}</React.Suspense>
          </AppProvider>
        </GlobalProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}

// $FlowFixMe
export default React.forwardRef(Provider);
