// @flow

import * as React from 'react';
import ReactDOM from 'react-dom';
import invariant from 'fbjs/lib/invariant';
import { InMemoryCache } from 'apollo-cache-inmemory';

import App from '../App';
import Provider from './Provider';

console.info('Client loading...');

const reactRoot = document.getElementById('react-root');
if (!reactRoot) {
  throw new Error('React root not found');
}
console.log('SSR Data:', window.__SSR_DATA__);
const ssrData = window.__SSR_DATA__;
const ssr = Boolean(ssrData);

if (!ssr) {
  ReactDOM.render(
    <Provider>
      <App />
    </Provider>,
    reactRoot,
  );
} else {
  const providerRef = React.createRef<Provider>();

  const onHydrateComplete = () => {
    const provider = providerRef.current;
    invariant(provider, 'Expected "provider"');

    provider.onHydrateComplete();
  };

  window.React = React;
  window.ReactDOM = ReactDOM;

  const apolloCache = new InMemoryCache();
  if (ssr) {
    apolloCache.restore(window.__APOLLO_STATE__);
  }

  ReactDOM.hydrate(
    // <React.StrictMode>
    <Provider ref={providerRef} apolloCache={apolloCache} ssr>
      <App />
    </Provider>,
    // </React.StrictMode>,
    reactRoot,
    onHydrateComplete,
  );
}
