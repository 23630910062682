// @flow

import { useReadData } from '../helpers/react-data';
import { useDataSources, useDataStateCache } from '../globals';

export const useCurrentUser = () => {
  const dataSources = useDataSources();
  const dataStateCache = useDataStateCache();
  const { currentUser: dataSource } = dataSources;

  // console.log('useCurrentUser', datasSources);

  const readData = useReadData(dataStateCache, dataSource);
  const data = readData();

  // console.log('useCurrentUser datas', data);

  return data;
};

export const useCurrentUserData = () => {
  const { currentUserData } = useDataSources();
  const dataStateCache = useDataStateCache();
  const readData = useReadData(dataStateCache, currentUserData);

  const data = readData();

  // console.log('useCurrentUsersData data', data);

  return data;
};
