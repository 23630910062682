// @flow

import { type DataState } from './dataState';
import { type DataSource } from './dataSource';

export type DataStateCache = {
  has: <Value, Identifier>(
    dataSource: DataSource<Value, Identifier>,
    identifier: Identifier,
  ) => boolean,
  get: <Value, Identifier>(
    dataSource: DataSource<Value, Identifier>,
    identifier: Identifier,
  ) => DataState<Value>,
  set: <Value, Identifier>(
    dataSource: DataSource<Value, Identifier>,
    identifier: Identifier,
    dataState: DataState<Value>,
  ) => void,
};

export type DataStateMap<Value, Identifier = void> = Map<Identifier, DataState<Value>>;

export type DataSourceStateWeakMap = WeakMap<DataSource<mixed, mixed>, DataStateMap<mixed, mixed>>;

export const yay = true;

export function createDataStateCache(): DataStateCache {
  const dataSourceStateWeakMap = new WeakMap();

  const has = (dataSource, identifier) => {
    if (!dataSourceStateWeakMap.has(dataSource)) {
      return false;
    }

    const dataStateMap = dataSourceStateWeakMap.get(dataSource);

    if (!dataStateMap) throw new Error('Expected "dataStateMap"');

    return dataStateMap.has(identifier);
  };
  const get = (dataSource, identifier) => {
    if (!dataSourceStateWeakMap.has(dataSource)) {
      throw new Error('Not found');
    }

    const dataStateMap = dataSourceStateWeakMap.get(dataSource);

    if (!dataStateMap) throw new Error('Expected "dataStateMap"');

    if (!dataStateMap.has(identifier)) {
      throw new Error('Not found');
    }

    const dataState = dataStateMap.get(identifier);

    if (!dataState) throw new Error('Expected "dataState"');

    return dataState;
  };
  const set = (dataSource, identifier, value) => {
    let dataStateMap;
    if (!dataSourceStateWeakMap.has(dataSource)) {
      dataStateMap = new Map();
      dataSourceStateWeakMap.set(dataSource, dataStateMap);
    } else {
      dataStateMap = dataSourceStateWeakMap.get(dataSource);
    }

    if (!dataStateMap) throw new Error('Expected "dataStateMap"');

    dataStateMap.set(identifier, value);
  };

  return {
    has,
    // $FlowFixMe
    get,
    set,
  };
}
