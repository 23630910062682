// @flow

import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Helmet from 'react-helmet-async';

// import HomePage from '../HomePage';
// import ProfilePage from '../ProfilePage';
// import CardsPage from '../CardsPage';
// import NewCardsPage from '../NewCardsPage';
// import CardSetPage from '../CardSetPage';
// import PrivacyPolicyPage from '../PrivacyPolicyPage';
// import TermsOfServicePage from '../TermsOfServicePage';

import Header from '../Header';
// import Footer from './Footer';
import DrawerSafeAreaView from '../HomePage/DrawerSafeAreaView';
import NavigationDrawer from '../HomePage/NavigationDrawer';

const HomePage = React.lazy(() => import('../HomePage'));
const ProfilePage = React.lazy(() => import('../ProfilePage'));
const CardsPage = React.lazy(() => import('../CardsPage'));
// const NewCardsPage = React.lazy(() => import('../NewCardsPage'));
// const CardSetPage = React.lazy(() => import('../CardSetPage'));
const PrivacyPolicyPage = React.lazy(() => import('../PrivacyPolicyPage'));
const TermsOfServicePage = React.lazy(() => import('../TermsOfServicePage'));
const CardSetsPage = React.lazy(() => import('../CardSetsPage'));
const CardSetPage = React.lazy(() => import('../CardSetPage'));

type Props = {};

// TODO: getDerivedStateFromError()
export default function App(props: Props) {
  const [showHamburgerMenu, setShowHamburgerMenu] = React.useState(false);
  const onHamburgerClick = React.useCallback(() => setShowHamburgerMenu(!showHamburgerMenu), [
    showHamburgerMenu,
    setShowHamburgerMenu,
  ]);

  return (
    // <React.StrictMode>
    <>
      <Helmet>
        {/* TODO: Read from config */}
        <title>GradRx</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1, user-scalable=no"
        />
        <style>{`
          body {
            overscroll-behavior: none;
          }
        `}</style>
      </Helmet>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          height: showHamburgerMenu ? '100vh' : 'initial',
          overflow: showHamburgerMenu ? 'hidden' : 'initial',
          backgroundColor: 'rgba(0, 122, 211, 1.000)',
        }}
      >
        <div style={{ height: '48px' }}>
          <Header onHamburgerClick={onHamburgerClick} />
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
          }}
        >
          <div
            style={{
              marginLeft: showHamburgerMenu ? '0%' : '-75%',
              width: '75%',
              height: 'calc(100vh - 48px)',
              flexShrink: 0,
              transition: 'margin-left 0.2s',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: 'rgba(255, 255, 255, 1.000)',
            }}
          >
            <NavigationDrawer />
          </div>
          <DrawerSafeAreaView onOverlayClick={onHamburgerClick} drawerShown={showHamburgerMenu}>
            <React.Suspense maxDuration={200} fallback="PAGE IS LOADING">
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/cardsets" component={CardSetsPage} />
                <Route path="/cardsets/:id" component={CardSetPage} />
                <Route path="/profile" component={ProfilePage} />
                <Route path="/cards" component={CardsPage} />
                {/* <Route path="/new-cards/:id" component={CardSetPage} />
          <Route path="/new-cards" component={NewCardsPage} /> */}
                <Route path="/privacy" component={PrivacyPolicyPage} />
                <Route path="/tos" component={TermsOfServicePage} />
                <Redirect to="/" />
              </Switch>
            </React.Suspense>
          </DrawerSafeAreaView>
        </div>
      </div>
      {/* <Footer /> */}
    </>
    // </React.StrictMode>
  );
}
