// @flow

import * as React from 'react';

import {
  createCurrentUserDataSource,
  createCurrentUserDataDataSource,
  createFirestoreDocumentDataSource,
} from './dataSources';
import { type DataSource, type DataStateCache, createDataStateCache } from '../helpers/react-data';

export type Global = {
  // // TODO: Get rid of this after Suspense?
  // server: boolean,
  firebase: $npm$firebase$App | null,
  dataStateCache: DataStateCache,
  dataSources: {
    currentUser: DataSource<$npm$firebase$auth$User | null>,
    currentUserData: DataSource<mixed | null>,
    firestoreDocument: DataSource<mixed | null>,
  },
};

const createDataSources = (firebase) => ({
  currentUser: createCurrentUserDataSource(firebase),
  firestoreDocument: createFirestoreDocumentDataSource(firebase),
  currentUserData: createCurrentUserDataDataSource(firebase),
});

type GlobalConfig = {
  firebase?: $PropertyType<Global, 'firebase'>,
  dataStateCache?: $PropertyType<Global, 'dataStateCache'>,
  dataSources?: $PropertyType<Global, 'dataSources'>,
};
const createGlobal = (config: GlobalConfig = {}) => {
  const {
    firebase = null,
    dataStateCache = createDataStateCache(),
    dataSources = createDataSources(firebase),
  } = config;
  return {
    firebase,
    dataStateCache,
    dataSources,
  };
};

export const globalContext = React.createContext<Global>(
  // $FlowFixMe
  null,
);

export const { Consumer: GlobalConsumer } = globalContext;

type GlobalProviderProps = {
  firebase: any,
  children: React.Node,
};
export const GlobalProvider = (props: GlobalProviderProps) => {
  const { firebase, children } = props;

  const dataStateCache = React.useMemo(createDataStateCache, []);
  const dataSources = React.useMemo(() => createDataSources(firebase), [firebase]);
  const value = React.useMemo(
    () =>
      createGlobal({
        firebase,
        dataStateCache,
        dataSources,
      }),
    [firebase, dataStateCache, dataSources],
  );

  return React.createElement(globalContext.Provider, { value }, children);
};
