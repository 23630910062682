// @flow

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// Firebase hack
if (
  typeof global === 'object' &&
  // eslint-disable-next-line no-restricted-globals
  typeof self === 'undefined'
) {
  global.self = global;
}

export default firebase;

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyDfGXhbpdCexXwwlfp4nd_XWvYSvPw5nm0',
  authDomain: 'gradrx-1337.firebaseapp.com',
  databaseURL: 'https://gradrx-1337.firebaseio.com',
  projectId: 'gradrx-1337',
  storageBucket: '',
  messagingSenderId: '407929414021',
};
firebase.initializeApp(config);

firebase.firestore().settings({
  timestampsInSnapshots: true,
});

export type ProviderId = 'facebook.com' | 'github.com';

export const providers = {
  'facebook.com': new firebase.auth.FacebookAuthProvider(),
  'github.com': (() => {
    const provider = new firebase.auth.GithubAuthProvider();
    // provider.addScope('public_repo');
    provider.setCustomParameters({
      allow_signup: 'false',
    });
    return provider;
  })(),
};
