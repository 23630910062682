// @flow

import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { withClientState } from 'apollo-link-state';

type Config = {
  cache: InMemoryCache,
  ssrMode?: boolean,
};

export default function createApolloClient(config: Config) {
  const { cache, ssrMode = false } = config;

  const stateLink = withClientState({
    cache,
    resolvers: {
      Query: {
        hello: () => ({
          hello: 'world',
          __typename: 'Hello',
        }),
      },
      // Mutation: {
      //   updateNetworkStatus: (_, { isConnected }, { cache }) => {
      //     const data = {
      //       networkStatus: {
      //         __typename: 'NetworkStatus',
      //         isConnected,
      //       },
      //     };
      //     cache.writeData({ data });
      //     return null;
      //   },
      // },
    },
  });

  const apolloClient = new ApolloClient({
    ssrMode,
    cache,
    link: stateLink,
  });

  return apolloClient;
}
