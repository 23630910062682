// @flow

import Observable from 'zen-observable';

export const createCurrentUserDataSource = (maybeFirebase: $npm$firebase$App | null) => {
  type Data = $npm$firebase$auth$User | null;
  console.log('createCurrentUserDataSource', maybeFirebase);

  // SSR hack
  if (!maybeFirebase) {
    return {
      get: () => null,
    };
  }

  const firebase = maybeFirebase;

  return {
    get: () =>
      new Promise<Data>((resolve) => {
        const subscription = firebase.auth().onAuthStateChanged((currentUser) => {
          resolve(currentUser || null);
          console.log('currentUser', currentUser);
          subscription();
        });
      }),
    getChanges: () =>
      new Observable<Data>((observer) => firebase.auth().onAuthStateChanged(observer)),
  };
};

export const createCurrentUserDataDataSource = (maybeFirebase: $npm$firebase$App | null) => {
  type Data = $npm$firebase$auth$User | null;
  console.log('createCurrentUserDataSource', maybeFirebase);

  const currentUserDataSource = createCurrentUserDataSource(maybeFirebase);
  const firestoreDocumentDataSource = createFirestoreDocumentDataSource(maybeFirebase);

  // SSR hack
  if (!maybeFirebase) {
    return {
      get: () => null,
    };
  }

  const firebase = maybeFirebase;

  return {
    get: () =>
      currentUserDataSource.get().then((currentUser) => {
        if (!currentUser) return null;
        const currentUserData = firestoreDocumentDataSource.get(`users/${currentUser.uid}`);
        return currentUserData;
      }),
    getChanges: () =>
      new Observable((observer) => {
        let subscription;

        currentUserDataSource
          .getChanges()
          .map((currentUser) => {
            if (!currentUser) return null;
            return firestoreDocumentDataSource.getChanges(`users/${currentUser.uid}`);
          })
          .subscribe((currentUserDataChanges) => {
            if (subscription) {
              subscription.unsubscribe();
            }
            subscription = currentUserDataChanges.subscribe(observer);
          });

        return () => {
          if (subscription) {
            subscription.unsubscribe();
          }
        };
      }),
  };
};

export const createFirestoreDocumentSnapshotDataSource = (
  maybeFirebase: $npm$firebase$App | null,
) => {
  type Data = $npm$firebase$firestore$DocumentSnapshot | null;

  // SSR hack
  if (!maybeFirebase) {
    return {
      get: () => null,
    };
  }

  const firebase = maybeFirebase;

  return {
    get: (docPath: string) =>
      firebase
        .firestore()
        .doc(docPath)
        .get(),
    getChanges: (docPath: string) =>
      new Observable((observer) =>
        firebase
          .firestore()
          .doc(docPath)
          .onSnapshot(observer),
      ),
  };
};

export const createFirestoreDocumentDataSource = (maybeFirebase: $npm$firebase$App | null) => {
  // SSR hack
  if (!maybeFirebase) {
    return {
      get: () => null,
    };
  }

  const firebase = maybeFirebase;

  return {
    get: (docPath: string) =>
      firebase
        .firestore()
        .doc(docPath)
        .get()
        .then((ds) => ds.data()),
    getChanges: (docPath: string) =>
      new Observable((observer) =>
        firebase
          .firestore()
          .doc(docPath)
          .onSnapshot(observer),
      ).map((ds) => ds.data()),
  };
};
