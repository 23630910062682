// @flow

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import { useCurrentUser } from '../../resources';

type Props = {
  onHamburgerClick: () => void,
};

export default function Header(props: Props) {
  const { onHamburgerClick } = props;
  const user = useCurrentUser();

  const signedUser = user && !user.isAnonymous ? user : false;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '12px 20px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <button type="button" onClick={onHamburgerClick}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div
          style={{
            marginLeft: '10px',
          }}
        >
          <Link
            to="/"
            style={{
              color: 'black',
            }}
          >
            GradRx
          </Link>
        </div>
      </div>
      <div>
        <Link to="/profile">
          {!signedUser && 'Login'}
          {signedUser && (
            <>
              <img
                className="mr-1 border rounded-circle"
                src={signedUser.photoURL}
                alt={signedUser.displayName}
                style={{
                  width: '24px',
                  height: '24px',
                }}
              />
            </>
          )}
        </Link>
      </div>
    </div>
  );
}
