// @flow

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './index.css';

import { config } from '@fortawesome/fontawesome-svg-core';

import App from './components/App';

config.autoAddCss = false;

export { GlobalProvider } from './globals';
export { default as AppProvider } from './components/AppProvider';

export default App;
